@media (min-width: 992px) {
  .container {
    max-width: 1000px !important;
  }
}
@media (max-width: 991px) {
  .foryou-div .foryou-wrapper {
    padding: 30px 0px;
  }
}

@media screen and (min-width: 1200px) {
  .error {
    max-width: 1360px !important;
  }
}

@media (min-width: 1024px) {
  .inner-div-media {
    width: 75% !important;
  }
  /* .pdf-image{
		height: 1200px !important;
		width: 775px !important;
	} */
}

@media (max-width: 1199px) {
  .left-content {
    min-width: 440px;
    max-width: 440px;
    background-size: 100%;
  }

  .inner-box-contents {
    width: 1020px;
  }

  .right-content {
    width: 580px;
  }

  .actual-price {
    width: 63%;
  }

  .client-info .nav-pills .nav-link {
    font-size: 13px;
  }

  .left-content:after {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }
}

@media (max-width: 1199px) {
  .signUpsection {
    max-width: 740px;
    width: 65%;
  }
}

@media (max-width: 1064px) {
  .appimg {
    width: 45%;
  }

  .footer-links ul li:last-child {
    width: 25.8%;
  }
}
@media (max-width: 1280px) {
  .gift-form-inputs-stripe {
    padding: 11px 14px;
  }
  .container-gift-grid {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-gap: 2vh;
    grid-gap: 2vh;
    gap: 2vh;
    justify-content: flex-start;
    width: 450px;
    margin: 3vh 90px 0px 0px;
  }
  .confirmation-container {
    gap: 20px;
  }
  .gift-text-lg {
    font-size: 43px;
    line-height: 48px;
  }
  .redeem-text-sm {
    font-size: 16px;
  }
  .gift-subscription-header {
    font-size: 22px;
  }
  .gift-subscription-text {
    font-size: 18px;
  }
  .container-gift-grid-form {
    margin: 28px 0px 0px 90px;
  }
  .gift-form-label {
    font-size: 13px;
  }
  .gift-form-inputs input {
    font-size: 13px;
  }
  .gift-form {
    gap: 9px;
    width: 400px;
    padding: 20px 2vw;
    border-radius: 21.333px;
  }
  .gift-form-header {
    margin-bottom: 4px;
  }
  .gift-text-div {
    margin-top: 20px;
    gap: 20px;
  }
  .gift-form-steps {
    font-size: 14px;
  }
  .gift-form-step-number {
    height: 20px;
    width: 20px;
  }
  .gift-form-inputs textarea {
    font-size: 13px;
  }
  .gift-text-note {
    font-size: 14px;
  }
  .gift-text-details span {
    font-size: 13px;
  }
  .gift-form-footer-text {
    font-size: 11px;
    width: 247px;
  }
  .gift-form-footer-text a {
    font-size: 11px;
  }
  .gift-form-pricing {
    font-size: 13px;
  }
  .gift-form-promo-btn {
    height: 47px;
  }
  .gift-form-subheader {
    font-size: 14px;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: url("../images/calendar-icon-md.svg") no-repeat;
    background-position: center;
  }
  .confirmation-box-container h1 {
    font-size: 51px;
  }
  .confirmation-box-container h2 {
    font-size: 25px;
  }
  .confirmation-box-container {
    gap: 20px;
    padding: 20px 50px;
  }
}

@media (max-width: 1274px) {
  .inner-box-contents {
    width: 860px;
    min-height: 550px;
  }
  .confirmation-box-container h1 {
    font-size: 38px;
  }
  .confirmation-box-container h2 {
    font-size: 22px;
  }
  .discountPercentage {
    font-size: 24px;
    font-weight: 700;
    padding-top: 15px;
  }

  .width-50:nth-child(1) {
    margin-right: 11px;
  }

  .logo img {
    width: 60%;
  }

  .logo {
    margin-bottom: 30px;
  }

  .left-content {
    min-width: 400px;
    max-width: 400px;
    background-size: 100% 100%;
  }

  .right-content {
    width: 460px;
  }

  .inner-box-contents {
    height: 460px;
  }

  .logo {
    margin-top: 70px;
  }

  .welcome-note h3 {
    font-size: 26px;
  }

  .welcome-note p {
    font-size: 13px;
  }

  .offer-inner-tag p {
    font-size: 10px;
  }

  .offer-inner-tag h2 {
    font-size: 18px;
  }

  .offer-tag {
    min-width: 125px;
    padding: 5px 13px 5px 0;
  }

  .actual-price {
    width: 63%;
    /*margin:5px 10px 0px 0;*/
  }

  .exact-amount span {
    font-size: 18px;
  }

  .exact-amount strong {
    font-size: 18px;
  }

  .exact-amount p {
    font-size: 14px;
  }

  .client-info .nav-pills .nav-link {
    font-size: 12px;
  }

  .exact-amount {
    padding-bottom: 2px;
  }

  .price-section {
    margin-bottom: 20px;
  }

  .client-info ul {
    margin-bottom: 20px !important;
  }

  .input-field label {
    font-size: 12px;
  }

  .input-field input {
    border-radius: 10px;
    /*min-height: 36px;*/
  }

  .rate-amount {
    height: 36px;
  }

  .btn-section button {
    padding: 10px 30px;
  }

  .width-60 {
    margin-right: 15px;
  }

  .width-50M {
    width: 35%;
  }

  .footer-links ul li:last-child {
    width: 25.8%;
  }

  .offerSession sup {
    position: absolute;
    top: -10px;
    background-color: #00d3de;
    padding: 14px;
    left: 20px;
    font-size: 9px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 4px;
  }

  .client-info ul a {
    font-size: 13px;
  }

  .input-field {
    margin-bottom: 20px;
  }
  .gift-form {
    padding: 14px 26px 18px 26px;
    width: 320px;
    gap: 8px;
    border-radius: 17px;
  }
  .gift-form-inputs-stripe {
    padding: 9px 11px;
  }
  .gift-form-header {
    font-size: 20px;
    text-align: center;
  }
  .gift-form-step2 {
    width: 32vw;
    gap: 5.6px;
  }
  .gift-form-label {
    font-size: 11px;
  }
  .gift-form-inputs input {
    padding: 10px 11px;
    font-size: 12px;
  }
  .gift-form-inputs textarea {
    padding: 10px 11px;
    font-size: 12px;
  }
  .gift-text-lg {
    font-size: 34px;
    line-height: 38px;
  }
  .redeem-text-sm {
    font-size: 14px;
  }
  .gift-subscription-header {
    font-size: 18px;
  }
  .gift-subscription-text {
    font-size: 15px;
  }
  .gift-form-steps {
    font-size: 11px;
  }
  .gift-form-confirm {
    padding: 14px 26px;
  }
  .gift-form-confirm-text {
    font-size: 13px;
  }
  .gift-text-sm {
    font-size: 15px;
  }
  .gift-form-help {
    font-size: 16px;
  }
  .gift-form-help-link {
    font-size: 16px;
  }
  .policy-section label {
    font-size: 12px;
  }
  .policy-section a {
    font-size: 12px;
  }
  .policy-section input {
    height: 16px;
  }
  .container-gift-grid {
    margin: 15px var(--Pading, 72px) 0px 0px;
    width: 320px;
  }
  .gift-text-div {
    margin-top: 10px;
  }
  .container-gift-grid-form {
    margin: 15px 0px 0px var(--Pading, 72px);
  }
  .gift-logo-sm {
    max-width: 74px;
  }
  .gift-form-step-number {
    height: 18px;
    width: 18px;
  }
  .gift-form-inputs {
    gap: 8px;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: url("../images/calendar-icon-sm.svg") auto;
    background-position: center;
  }
  .gift-text-note {
    font-size: 11px;
  }
  .gift-text-details span {
    font-size: 13px;
  }
  .gift-form-footer-text {
    font-size: 11px;
    width: 247px;
  }
  .gift-form-footer-text a {
    font-size: 11px;
  }
  .gift-form-pricing {
    font-size: 13px;
  }
  .gift-form-promo-btn {
    height: 40px;
  }
  .gift-form-subheader {
    font-size: 12px;
  }
  .confirmation-container {
    gap: 20px;
  }
  .gift-form-help {
    margin-top: 26px;
  }
  .right-confimation-redeem-container {
    width: 100%;
    gap: 5px;
  }
  .confirmation-unplug-detils > h2 {
    font-size: 48px;
    line-height: 48px;
  }
  .confirmation-unplug-detils > span {
    font-size: 18px;
  }
  .right-bubble span {
    font-size: 15px;
  }
  .right-bubble {
    margin: 40px 40px;
  }
  .app-images > a {
    width: 150px;
  }
  .right-confirmation-app-details > span {
    font-size: 18px;
  }
  .right-confirmation-app-details > span > a {
    font-size: 18px;
  }
}

/* @media(min-width:1024px){
	
	.pdf-image{
		height: 1000px !important;
		max-width: 100% !important;
	}

} */

@media screen and (max-width: 991px) {
  .body-contents .contents-main .container .error-div .error-div-inner {
    padding: 50px;
  }
}

@media (max-width: 991px) {
  .signUpsection {
    max-width: 740px;
    width: 95%;
  }

  footer {
    padding: 40px 0;
  }

  .footer-links ul li a {
    font-size: 12px;
  }

  .footer-links ul li strong {
    font-size: 13px;
  }

  .mennubar nav {
    background-color: transparent;
    padding: 35px 0 25px;
  }

  @media (max-width: 768px) {
    .inner-box-contents {
      width: 720px;
      min-height: 480px;
    }

    .width-50:nth-child(1) {
      margin-right: 9px;
    }

    .input-field {
      margin-bottom: 15px;
    }

    .logo {
      margin-top: 45px;
    }

    .input-field input {
      border-radius: 10px;
      min-height: 0;
    }

    .welcome-note h3 {
      font-size: 18px;
    }

    .left-content {
      min-width: 340px;
      max-width: 340px;
      padding-left: 45px;
      padding-right: 50px;
    }

    .input-field .StripeElement {
      border-radius: 10px;
      min-height: 0;
      padding-top: 10px;
    }

    .width-35 {
      width: 36%;
    }

    .offerSession sup {
      left: 5px;
    }

    .testAlert {
      top: -60px;
    }

    .right-content {
      width: 380px;
    }

    .width-50M {
      width: 35% !important;
    }

    .error-alert .responsive-error {
      bottom: -32px;
      width: 100%;
    }

    .btn-responsive {
      position: relative;
      top: 15px;
    }

    .offer-inner-tag p {
      font-size: 8px;
    }

    .width-60 {
      width: 54%;
    }

    .offer-inner-tag h2 {
      font-size: 14px;
    }

    .offer-inner-tag h2 small {
      font-size: 11px;
    }

    .right-content {
      padding: 15px;
    }

    .actual-price {
      width: 59%;
    }

    .exact-amount span {
      font-size: 16px;
    }

    .exact-amount strong {
      font-size: 16px;
    }

    .exact-amount p {
      padding-bottom: 3px;
      font-size: 12px;
      line-height: 0.5;
      padding-top: 5px;
    }

    .exact-amount span:after {
      top: 8px;
    }

    .client-info .nav-pills .nav-link {
      font-size: 11px;
    }

    .client-info ul li:after {
      height: 24px;
    }

    .calucation-area {
      width: 75px;
    }

    .calucation-area {
      margin-bottom: 5px;
    }

    .welcome-note p {
      font-size: 12px;
    }

    .calucation-area:last-child {
      width: 37% !important;
    }

    .mennubar nav {
      padding: 30px 0 25px;
    }

    .upLink {
      text-align: center;
    }

    .upLink a:nth-child(1) {
      padding-left: 0;
    }

    .signUpsection {
      max-width: 700px;
      width: 95%;
    }
  }
}

/* css for new pages */
/* css for new pages */
/* css for new pages */
/* css for new pages */
/* css for new pages */
/* css for new pages */
/* css for new pages */

@media (max-width: 991px) {
  .header .header-nav .collapse {
    margin-top: 0px;
  }
  .present-div .gift-div {
    margin-bottom: 42px;
  }
  /* .foryou-wrapper .inner-div .takeagift p {
		padding: 0px;
	} */
  /* .foryou-wrapper .inner-div .takeagift p {
		width: 96%;
	} */
  .foryou-wrapper .inner-div .abc {
    /* padding-left: 42px; */
  }
  .foryou-div .inner-div {
    width: 86%;
  }
  .present-div .col-lg-5 {
    order: 2;
  }
  .present-div {
    background-position: center -520px;
  }
}
@media (max-width: 1199px) {
  .foryou-div .inner-div .takeagift p {
    padding: 0px;
    /* padding-top: 20px; */
  }
  .foryou-wrapper .inner-div .takeagift input {
    /* padding-top: 20px; */
  }
}

@media (max-width: 767px) {
  .present-div .gift-div {
    text-align: center;
  }
  .present-div .gift-div .gift-heading {
    font-size: 35px;
  }
  .foryou-div .inner-div .takeagift p {
    padding: 0px;
  }
  .foryou-div .inner-div {
    width: 100%;
    position: absolute;
    top: 90px;
    width: 100%;
    /* transform: rotate(20deg) translateX(0); */
  }
  .foryou-wrapper .inner-div .gift h6 {
    font-size: 24px;
  }
  .foryou-wrapper .inner-div .gift p {
    font-size: 18px;
    font-weight: 30px;
  }
  .foryou-wrapper .inner-div .takeagift p {
    font-size: 18px;
  }

  /* .inner-div-download {
			margin-left: 15% !important;
		} */
}

@media (max-width: 768px) {
  .home-alert {
    margin-top: 75px;
    z-index: 99;
    max-width: 93%;
  }
  .alertCoupon {
    margin-top: 0;
  }

  .form-div {
    margin-top: 55px;
  }
  .gift-text-lg {
    font-size: 50px;
    line-height: 50px;
  }
  .redeem-text-sm {
    font-size: 1rem;
  }
}
@media (max-width: 767px) {
  .error-div-inner h1 {
    font-size: 140px !important;
  }

  .error-div-inner h2 {
    font-size: 20px !important;
  }

  .error-div-inner {
    padding: 50px 0 !important;
  }
}

@media (max-width: 700px) {
  /* .inner-div-download {
			margin-left: 19% !important;
		} */
}

@media (max-width: 700px) {
  /* .inner-div-download {
			margin-left: 19% !important;
		} */
}

/* @media only screen and (min-width) */
@media (max-width: 615px) {
  .quantity-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /* .inner-div-download {
			margin-left: 27% !important;
		} */
}

@media (max-width: 572px) {
  /* .inner-div-download {
			width: 625px;
			margin-left: 27% !important;
		} */
}
@media (max-width: 533px) {
  .takeagift img {
    width: 100%;
    height: 200px;
  }
}
@media (max-width: 490px) {
  /* .pdf-image{
			height: 1450px !important;
			max-width: 100% !important;
		} */

  .xyz {
    padding: 25px;
  }
  /* .inner-div-download {
			margin-top: 64% !important;
			width: 784px;
			margin-left: 11% !important;
		} */
  .home-alert {
    margin-top: 55px;
    z-index: 99;
    max-width: 90%;
  }

  .alertCoupon {
    margin-top: 0;
  }

  .form-div {
    margin-top: 55px;
  }
  .gift-logo-sm {
    max-width: 95px;
  }
  .policy-section input {
    cursor: pointer;
    height: 16px;
  }
  .gift-text-div {
    width: 100%;
  }
  .gift-text-lg {
    font-size: 46px;
    line-height: normal;
    text-align: center;
  }
  .gift-text-sm {
    color: #1a1919;
    text-align: center;
    font-family: SF Pro Display;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    width: 100%;
  }
  .gift-subscription-header {
    font-size: 18px;
    font-style: normal;
  }
  .gift-form {
    display: flex;
    padding: 24px 24px 34px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    width: 340px;
  }
  .gift-logo-div {
    justify-content: center;
  }
  .gift-form-header {
    font-size: 24px;
    font-style: normal;
    line-height: normal;
    text-align: center;
  }
  .gift-form-label {
    font-size: 12px;
    line-height: normal;
  }
  .policy-section label {
    font-size: 12px;
    font-style: normal;
  }
  .policy-section a {
    font-size: 12px;
    font-style: normal;
  }
  .gift-form-inputs-div {
    gap: 8px;
  }
  .gift-form-inputs input {
    font-size: 12px;
  }
  .gift-form-inputs textarea {
    font-size: 12px;
  }
  .gift-form-confirm-text {
    font-size: 16px;
  }
  .gift-form-help {
    font-size: 14px;
  }
  .gift-form-help-link {
    font-size: 14px;
  }
  .gift-form-steps {
    width: 342px;
  }
  .gift-form-step-line {
    width: 15%;
  }
  .gift-form-step-name {
    font-size: 14px;
  }
  .gift-form-step {
    padding: 0px 14px;
  }
  .gift-form-step2 {
    width: 100%;
  }
  .gift-text-note {
    font-size: 14px;
    width: 286px;
    height: auto;
    overflow-wrap: break-word;
  }
  .gift-text-details span {
    font-size: 18px;
  }
  .gift-form-pricing {
    font-size: 18px;
  }
  .gift-form-subheader {
    font-size: 18px;
  }
  .confirmation-box-container {
    width: 100%;
    padding: 5vw;
  }
  .confirmation-box-container h1 {
    font-size: 46px;
    line-height: 50px;
  }
  .confirmation-box-container h2 {
    width: 100%;
    font-size: 18px;
  }
  .gift-card-span {
    font-size: 14px;
  }
  .gift-form-confirm {
    padding: 16px 24px;
  }
  .unplug-logo-redeem {
    margin: 48px;
    width: 26vw;
  }
  .right-confirmation-redeem {
    padding: 0px;
  }
  .confirmation-unplug-detils {
    margin-top: 10vh;
  }
  .confirmation-unplug-detils > h2 {
    font-size: 46px;
    line-height: normal;
  }
  .right-bubble span {
    font-size: 16px;
    font-style: normal;
  }
  .confirmation-unplug-detils > span {
    width: 100%;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
  }
  .right-confimation-redeem-container {
    width: 100%;
    margin: 10vh 4vw;
  }
  .app-images > a {
    width: 154.571px;
    height: 46px;
  }
  .right-confirmation-app-details > span {
    font-size: 16px;
  }
  .right-confirmation-app-details > span > a {
    font-size: 16px;
  }
  .yoga-image {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    object-fit: cover;
  }
  .container-gift-grid {
    margin: auto;
  }
  .container-gift-grid-form {
    margin: auto;
    padding-top: 50px;
    margin-bottom: 20px;
  }
  .gift-grid-column-left {
    margin-bottom: 20px;
  }
}
@media (max-width: 424px) {
  .congrats h3 {
    font-size: 27px;
  }
  .congrats p {
    font-size: 17px;
  }
}

@media (max-width: 387px) {
  .whole-top-wrapper .header .header-nav .navbar-nav {
    margin: auto !important;
  }
  .whole-top-wrapper .header .header-nav {
    flex-direction: column !important;
  }
  .navbar-light .navbar-brand {
    margin: auto;
  }
}
@media (max-width: 375px) {
  .pdf-border {
    height: 185px !important;
  }
  .home-alert {
    margin-top: 60px;
    z-index: 99;
    max-width: 92%;
  }

  .alertCoupon {
    margin-top: 0px;
  }

  .form-div {
    margin-top: 60px;
  }
}

@media (max-width: 351px) {
  .foryou-div {
    height: 745px;
  }
  /* .takeagift img {
			width: 100%;
			height: 280px;
		} */
}

@media (max-width: 320px) {
  .home-alert {
    margin-top: 60px;
    z-index: 99;
    max-width: 90%;
  }

  .alertCoupon {
    margin-top: -15px;
  }

  .form-div {
    margin-top: 85px;
  }
}

@media (max-width: 600px) {
  .gift-grid {
    grid-template-columns: 1fr;
  }
}
