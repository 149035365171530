/* html {
    position: relative;
    height: 100%;
}

body {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    /*background-color: #F6F6F6;*/
/* background-color: #0091FF;
    font-family: 'Montserrat', sans-serif !important;
    position: relative;
    height: 100%;
} */

.main_wrapper {
  display: flex;
  background-color: #ecf7ff;
  align-items: center;
  min-height: 100vh;
  position: relative;
}

@font-face {
  font-family: "sf pro display";
  font-style: normal;
  font-weight: 500;
  src: url("../font/sf pro display/SFPRODISPLAYREGULAR.OTF");
}
@font-face {
  font-family: "sf pro display";
  font-style: heavy;
  font-weight: 600;
  src: url("../font/sf pro display/SFPRODISPLAYMEDIUM.OTF");
}
@font-face {
  font-family: "sf pro display";
  font-style: bold;
  font-weight: 700;
  src: url("../font/sf pro display/SFPRODISPLAYBOLD.OTF");
}

.inner-box-contents {
  /* width: 75%; */
  width: 1191px;
  background-color: #fff;
  min-height: 600px;
  -webkit-box-shadow: 9px 12px 12px -10px rgba(100, 100, 100, 0.4);
  -moz-box-shadow: 9px 12px 12px -10px rgba(100, 100, 100, 0.4);
  box-shadow: 9px 12px 12px -10px rgba(100, 100, 100, 0.4);
  margin: 0 auto;
  border-radius: 20px;
  position: relative;
  height: 624px;
}

.left-content {
  background: url(../images/banner-bunp.png);
  height: 100%;
  /* width: 45%; */
  min-width: 550px;
  max-width: 550px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 60px;
  padding-right: 100px;
  float: left;
}

.left-content:after {
  content: "";
  background-image: linear-gradient(
    to right top,
    rgba(0, 145, 255, 0.93),
    rgba(0, 145, 255, 0.91),
    rgba(0, 136, 255, 0.9),
    rgba(87, 179, 255, 0.73),
    rgba(68, 173, 253, 0.58)
  );
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 20px;
}

.logo {
  margin-top: 120px;
  margin-bottom: 60px;
  position: relative;
  z-index: 1;
}

.welcome-note {
  position: relative;
  z-index: 1;
}

.welcome-note h3 {
  font-size: 30px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
}
.captal {
  text-transform: uppercase;
}

.welcome-note h3 strong {
  font-weight: 800;
}

.welcome-note h3:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 50px;
  background-color: rgba(255, 255, 255, 0.5);
  bottom: -15px;
  left: 0;
}

.welcome-note p {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  position: relative;
  z-index: 1;
  margin-top: 30px;
  opacity: 0.87;
  line-height: 29px;
}

.right-content {
  padding: 30px;
  float: left;
  width: 641px;
  height: 100%;
}

.price-section {
  border-radius: 10px;
  /*background-color: #E4EEFF;*/
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f3f6ff),
    color-stop(#f0f8ff),
    color-stop(#ecfbff),
    color-stop(#eafdff),
    to(#e9fffe)
  );
  background-image: -webkit-linear-gradient(
    left,
    #f3f6ff,
    #f0f8ff,
    #ecfbff,
    #eafdff,
    #e9fffe
  );
  background-image: linear-gradient(
    to right,
    #f3f6ff,
    #f0f8ff,
    #ecfbff,
    #eafdff,
    #e9fffe
  );
  margin-bottom: 40px;
  padding: 15px;
}

.offer-tag {
  min-width: 150px;
  padding: 10px 13px 10px 0;
  background-color: #4d4d4d;
  display: inline-block;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}

.offer-tag:before {
  position: absolute;
  content: "";
  border-width: 0px 0px 7px 50px;
  border-color: #fd000017 #292929 #292929 #00f0;
  border-style: solid;
  top: -7px;
  left: 0px;
}

.offer-inner-tag {
  border: 1px dashed #fff;
  border-left: none;
  padding: 5px 15px 5px 15px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.offer-inner-tag p {
  font-size: 12px;
  margin-bottom: 0;
  color: #fff;
  text-transform: uppercase;
}

.offer-inner-tag h2 {
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  color: #fff;
}

.offer-inner-tag h2 small {
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
}

.actual-price {
  border-radius: 10px;
  /*background-image: linear-gradient(to right, #f3f6ff, #f0f8ff, #ecfbff, #eafdff, #e9fffe);*/
  /*float: right;*/
  width: 63%;
  display: inline-block;
  /*margin:12px 10px 0px 0;*/
  text-align: center;
}

.exact-amount span {
  position: relative;
  color: #2e2e2e;
  font-size: 19px;
  font-weight: 300;
  margin-right: 10px;
}

.exact-amount span:after {
  position: absolute;
  content: "";
  background-color: #000;
  top: 11px;
  left: -5px;
  width: 115%;
  height: 2px;
}

.offerSession {
  position: relative;
  background-color: #fff;
  display: inline-block;
  width: 37%;
  text-align: center;
  border-radius: 4px;
  box-shadow: 2px 5px 15px -6px #ddd;
}

.offerSession sup {
  position: absolute;
  top: -10px;
  background-color: #00d3de;
  padding: 14px;
  left: 20px;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 4px;
}

.discountPercentage {
  font-size: 30px;
  font-weight: 700;
  padding-top: 15px;
}

.discountPercentage small {
  font-size: 70%;
}

.exact-amount strong {
  font-weight: 700;
  color: #2e2e2e;
  font-size: 19px;
}

.exact-amount {
  padding-bottom: 5px;
  padding-top: 5px;
}

.exact-amount p {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 13px;
  color: #4d4d4d;
}

.client-info ul a {
  color: rgba(100, 100, 100, 0.6) !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  padding: 0 !important;
}

.client-info ul li {
  position: relative;
  padding: 0px 40px 0 0;
}

.client-info ul li a:hover {
  border-color: transparent !important;
  cursor: pointer;
}

.client-info ul li:nth-child(2) {
  padding-left: 30px;
}

.client-info ul li:after {
  position: absolute;
  background-color: #bbbbbb94;
  height: 30px;
  width: 2px;
  top: -5px;
  right: 0;
  content: "";
}

.client-info ul {
  margin-bottom: 30px !important;
  border-bottom: none !important;
}

.client-info ul li:nth-child(2):after {
  background-color: #fff;
}

.client-info ul a.active,
.client-info ul .show > a {
  background-color: transparent !important;
  color: #0091ff !important;
  font-weight: 500 !important;
  /*font-size: 16px;*/
  padding: 0 !important;
  border-color: transparent !important;
}

.input-field {
  margin-bottom: 30px;
}

.input-field label {
  font-weight: 400;
  font-size: 13px;
  color: black;
}

.gift-section {
  float: left;
}

.input-field input {
  border-radius: 10px;
  min-height: 45px;
}

.input-field .StripeElement {
  border-radius: 10px;
  /* min-height: 45px; */
  padding: 16px 24px;
}

.input-field input:focus {
  background-color: #f3f3f3;
  outline: none;
  box-shadow: none;
  border-color: #80bdff00;
}

.calucation-area {
  width: 95px;
  display: inline-block;
  margin-bottom: 15px;
}

.btn-section button {
  background-color: #003660;
  padding: 15px 30px;
  text-transform: uppercase;
  font-size: 14px;
  color: #fff;
  border-radius: 25px;
}

.rate-amount {
  min-height: 45px;
  background-color: #f3f3f3;
  line-height: 2.9;
  border-radius: 10px;
  text-align: center;
  padding: 0px 15px;
  transition: all 0.5s;
}

.btn-section button:hover {
  background-color: rgba(0, 54, 96, 0.95);
  box-shadow: 1px 1px 6px 0px #b5b5b5;
  transition: all 0.5s;
}

.btn-section button:focus {
  outline: 0px auto -webkit-focus-ring-color;
}

.mennubar {
  min-height: 110px;
  /*background-color: #FFFFFF;*/
  background-color: #0a95ff;
  box-shadow: 0px 0px 10px -5px #989898;
  margin-bottom: 30px;
}

.mennubar nav {
  background-color: transparent;
  padding: 42px 0 25px;
  width: 100%;
}

.upLink a {
  padding: 10px 30px;
  margin: 0 10px;
  color: #393939;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #fff;
}

.upLink a {
  text-decoration: none;
}

.contactbtn {
  background-color: #0091ff;
  /*background-image: linear-gradient(to right, #0091ff, #47a7ff, #70bcff, #97d0ff, #bee3ff);*/
  text-transform: uppercase;
  font-size: 14px;
  color: #fff !important;
  border-radius: 25px;
  text-transform: capitalize;
}

.contactbtn:hover {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 1px 1px 6px 0px #b5b5b5;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  color: #0091ff !important;
  text-decoration: none;
}

.jumbotron-custom {
  max-width: 910px;
  width: 100%;
  margin: 30px auto 60px;
  /*background-color: #fff;*/
  border-radius: 14px;
  padding: 50px 50px;
  /*box-shadow: 0px 0px 10px -5px #BFBFBF;*/
}

.head-title {
  text-align: center;
  margin-bottom: 30px;
}

.head-title h2 {
  font-size: 30px;
  /*color: #212121;*/
  color: #fff;
  font-weight: 700;
}

.head-title h6 {
  font-size: 17px;
  color: rgba(255, 255, 255, 0.84);
  font-weight: 500;
}

.jumbotronContent {
  width: 80%;
  text-align: center;
  margin: 0 auto;
}

.jumbotronContent p {
  color: rgba(255, 255, 255, 0.56);
  font-size: 16px;
  line-height: 2;
}

.coyLink {
  margin-top: 30px;
}

.copyLink p {
  background-color: #41aeff;
  border-radius: 10px;
  display: inline-block;
  padding: 9px 20px;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
}

.copyLink button {
  display: block;
  font-size: 13px;
  /*color: #212121;*/
  font-weight: 500;
  margin: 0 auto;
  border: none;
  background-color: transparent;
  color: #fff;
  text-transform: uppercase;
}

.copyLink a:hover {
  text-decoration: none;
}

button:focus {
  outline: 0px auto -webkit-focus-ring-color;
}

.primary-button {
  color: #fff;
  padding: 15px 40px;
  border-radius: 36px;
  font-size: 16px;
  background-image: linear-gradient(
    to right,
    #0091ff,
    #379dfb,
    #46a4f7,
    #7ac3ff,
    #baddf7
  );
  border: none;
  text-transform: uppercase;
}

.buttonBig {
  margin-top: 50px;
  text-align: center;
}

.primary-button:hover {
  color: #fff;
}

.footer-link {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.footer-link p {
  /*color: rgba(33, 33, 33, 0.71);*/
  color: rgba(255, 255, 255, 0.71);
  font-size: 18px;
}

.footer-link a {
  color: #fff;
}

.footer-link a:hover {
  text-decoration: none;
}

.head-title p {
  color: rgba(255, 255, 255, 0.71);
  font-size: 14px;
}

.signUpsection {
  max-width: 660px;
  width: 55%;
  position: relative;
}

.signUpsection .jumbotronContent {
  text-align: left;
  width: 100%;
}

/********************************************/

/* The container */
.policy-section label {
  /* display: inline-block;
  position: relative;
  padding-left: 21px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #818181; */
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: SF Pro Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;
}

/* Hide the browser's default checkbox */
.policy-section input {
  cursor: pointer;
  height: 16px;
  width: 16px;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 4px;
  /* background-color: transparent; */
  border: 1px solid #c02032;
  /* background: #fff; */
}

/* On mouse-over, add a grey background color */
.policy-section label:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.policy-section label input:checked ~ .checkmark {
  background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.policy-section label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.policy-section label .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid #c02032;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.policy-section {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
}

.policy-section a {
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: SF Pro Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.buttonBig .signIn {
  margin-top: 15px;
}

.buttonBig .signIn p {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.71);
}

.buttonBig .signIn p a {
  color: #fff;
  font-weight: 700;
}

a:hover {
  text-decoration: none;
}

.action-btn {
  text-align: center;
}

.kickOff {
  max-width: 606px;
  width: auto;
}

footer {
  height: 160px;
  /*background-color: #fff;*/
  background-color: #1d9eff;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 40px;
}

.footer-links ul {
  padding-left: 0;
  text-align: center;
}

.footer-links ul li {
  display: inline-block;
  width: 14%;
}

.footer-links ul li:last-child {
  width: 35.8%;
}

.footer-links ul li strong {
  display: block;
  font-weight: 500;
  color: #fff;
  font-size: 15px;
  padding-bottom: 12px;
  position: relative;
  margin-bottom: 10px;
}

.footer-links ul li strong:after {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  content: "";
  height: 2px;
  width: 22px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.footer-links ul li a {
  color: #212121;
  font-weight: 300;
  font-size: 13px;
  margin-right: 10px;
}

.Social-icon {
  padding: 5px;
}

.width-50 {
  width: 48%;
  display: inline-block;
}

.width-50:nth-child(1) {
  margin-right: 16px;
}

.width-60 {
  width: 56%;
  display: inline-block;
  margin-right: 21px;
}

.width-40 {
  width: 40%;
  display: inline-block;
  float: right;
}

.width-35 {
  width: 44%;
  display: inline-block;
  float: left;
  margin-bottom: 35px;
  margin-right: 21px;
}

.width-50M {
  width: 50%;
}

.btn-section {
  float: left;
  width: 100%;
}

.error-alert {
  position: relative;
}

.error-alert label {
  color: #ff0000;
}

.error-alert input {
  /* border-color: #FFC8C8; */
  /* background-color: #FFF6F6; */
  background-image: url("../images/error-icon.png");
  background-repeat: no-repeat;
  background-position: 96% 50%;
}

.alert-message {
  /* position: absolute; */
  /* bottom: -20px; */
  left: 5px;
  color: #dc3333;
  text-align: left;
  font-size: 11px;
}

/*.required-alert label {*/
/*position: relative;*/
/*}*/

/*.required-alert label:after {*/
/*content: '(Required)';*/
/*position: absolute;*/
/*top: 0;*/
/*left: 120%;*/
/*color: #FF2C2C;*/
/*}*/

.required-alert input {
  border-color: #ff2c2c;
}

.client-info {
  position: relative;
}

.alert-position {
  /* position: absolute; */
  /* width: 573px; */
  width: 100%;
  top: -2px;
}

.home-alert {
  position: absolute;
  top: -105px;
  padding-right: 1 rem;
}

.alert-success {
  color: #155724;
  background-color: #fafffb;
  border-color: #c3e6cb;
}

.custom_small_err {
  width: 250px;
}

.alert-danger {
  margin-bottom: 0px;
  border-color: #ffc8c8;
  background-color: #fff6f6;
  color: #ff2c2c;
}

.alert-warning {
  background-color: #fffbed;
}

.client-info ul li {
  position: relative;
  padding: 0px 30px 0 0;
}

.client-info ul a {
  font-size: 12px !important;
}

.offerSession {
  width: 37%;
}

.mt-10 {
  margin-top: 10px;
}

.hidden {
  position: fixed;
  bottom: 0;
  right: 0;
  pointer-events: none;
  opacity: 0;
  transform: scale(0);
}

.error-alert .relative-left {
  padding-left: 10px;
  position: static;
}
.gift-card-outside {
  float: left;
  color: #dc3333;
  font-size: 11px;
  margin-left: 5px;
}
.terms_services {
  color: #0090ff;
}

.loader,
.loader:before,
.loader:after {
  background: #bbbbbb;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader:before {
  left: -1.5em;
}

.loader {
  text-indent: -9999em;
  margin: 8em auto;
  position: relative;
  font-size: 4px;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:after {
  left: 1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0 #bbbbbb;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em #bbbbbb;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0 #bbbbbb;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em #bbbbbb;
    height: 5em;
  }
}

.adjust {
  min-height: 20px;
  height: auto;
}

.mt {
  margin-top: 50px;
}

.InputElement {
  font-family: "Montserrat", sans-serif;
}

.alertCoupon {
  position: relative;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.upLink {
  float: right;
}

.testAlert {
  min-height: 33px;
  padding: 5px 15px;
  font-size: 13px;
  /*position: relative;*/
  padding-right: 30px;
  top: -39px;
}

.testAlert button {
  padding: 5px 10px 5px 5px !important;
}

.changebtnColor {
  background-color: transparent;
  background-image: none;
  border: 2px solid #fff;
}

.changebtnColor:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.navbar-light .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.95);
}

.signColor .input-field label {
  color: #212529;
}

.link-copy {
  background: transparent !important;
}

.link-copy:hover {
  background: #007bff !important;
  color: #ffffff !important;
}
.calucation-area-first {
  float: left;
}

/* new css for new pages  */
/* new css for new pages  */
/* new css for new pages  */
/* new css for new pages  */
/* new css for new pages  */

html,
body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-smoothing: antialiased; */
}

body {
  font-family: "Montserrat", sans-serif;
}

.header .header-nav .collapse {
  margin-top: 27px;
}
.header .header-nav .collapse ul li a {
  font-size: 16px;
  padding-right: 50px;
  color: rgba(86, 86, 86, 1);
}
.header .header-nav .collapse ul li a:hover {
  color: rgba(192, 32, 50, 1);
}
.present-div {
  margin-top: 100px;
  font-family: "Montserrat", sans-serif;
  background-image: url(../images/Header_bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.present-div .form-div {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.8);
  padding: 30px 30px 19px 30px;
  margin-bottom: 42px;
}

.present-div .form-div .form-group label {
  color: black;
  font-size: 13px;
}

.form-control::-webkit-input-placeholder {
  color: rgba(54, 59, 66, 1);
  font-size: 14px;
}
.form-control::-moz-placeholder {
  color: rgba(54, 59, 66, 1);
  font-size: 14px;
}
.form-control:-ms-input-placeholder {
  color: rgba(54, 59, 66, 1);
  font-size: 14px;
}
.form-control:-moz-placeholder {
  color: rgba(54, 59, 66, 1);
  font-size: 14px;
}
.purchase-button {
  margin-top: 130px;
}
.present-div .form-div .purchase-button .purchase-btn {
  margin-top: 20px;
  background: rgba(192, 32, 50, 1);
  border-radius: 6px;
  border: 1px solid rgba(112, 112, 112, 1);
  text-transform: uppercase;
}
/* .present-div .form-div .purchase-button .purchase-btn span{
    padding-top: 16px;
    padding-bottom: 16px;
} */
.present-div .form-div .purchase-button .purchase-btn:focus {
  box-shadow: none;
}
.present-div .form-div .form-para p {
  color: rgba(129, 129, 129, 1);
  font-size: 14px;
  margin: 20px;
  text-align: center;
}
.present-div .gift-div .gift-heading {
  font-weight: 700;
  font-size: 45px;
}
.present-div .gift-div p {
  font-size: 18px;
  text-align: center;
}
.present-div .gift-div .quantity {
  color: black;
  margin-top: 65px;
  font-weight: 500;
  font-size: 18px;
}
.present-div .gift-div .quantity-div .minus-btn {
  background: rgba(227, 230, 233, 1);
  border: none;
  color: rgba(71, 74, 77, 1);
}
.present-div .gift-div .quantity-div .minus {
  width: 48px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-left: 20px;
}
.present-div .gift-div .quantity-div .minus:focus {
  box-shadow: none;
}
.present-div .gift-div .quantity-div .text-div {
  margin-left: 6px;
  border: 1px solid rgba(227, 230, 233, 1);
  width: 56px;
}
.present-div .gift-div .quantity-div .plus-btn {
  margin-left: 6px;
  background: rgba(227, 230, 233, 1);
  border: none;
  color: rgba(71, 74, 77, 1);
}
.present-div .gift-div .quantity-wrapper .amount {
  margin-top: 60px;
}
.amount h6 {
  font-weight: 700;
  color: rgb(0, 0, 0);
  font-size: 24px;
}
.work-div {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 136px;
}
.work-div .work-title {
  margin-top: 110px;
}
.work-title .heading {
  font-size: 40px;
  font-weight: 400;
}
.work-div .work-row {
  margin-top: 80px;
}
.work-row .row-logo {
  width: 126px;
  height: 126px;
  background: rgba(246, 246, 246, 1);
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.work-row .purchase-title {
  margin-top: 32px;
  text-transform: uppercase;
  font-size: 18px;
  color: rgba(54, 59, 66, 1);
  font-weight: 700;
}
.work-row .purchase-para {
  margin-top: 21px;
  font-size: 18px;
  line-height: 28px;
  color: rgba(149, 152, 155, 1);
}
.work-row .row-logo {
  margin: auto;
}

.foryou-div {
  /* background: url(../images/rotate-img.png); */
  font-family: "sf pro display", sans-serif;
  border: 9px solid #88c4ec;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 876px;
  height: 584px;
  overflow: hidden;
  margin: auto;
  max-width: 100%;
}
.foryou-div .inner-div {
  position: absolute;
  top: 90px;
  width: 55%;
}
.foryou-div .modded-container {
  height: 100%;
}
.foryou-div .foryou-wrapper {
  padding: 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}
.foryou-wrapper .inner-div .gift h6 {
  color: #1a1919;
  font-size: 40px;
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
}

.foryou-wrapper .inner-div .gift p {
  font-size: 27px;
  font-weight: 600;
  color: #1a1919;
  margin-top: 15px;
  line-height: 40px;
  text-align: center;
}

.foryou-wrapper .inner-div .takeagift p {
  /* font-size: 18px; */
  text-align: center;
  /* color: rgba(0, 41, 121, 1);
    font-weight: 200; */
}
.foryou-wrapper .inner-div .takeagift p a {
  color: #ffffff;
  text-decoration: underline;
}

.foryou-wrapper .inner-div .takeagift input {
  /* font-weight: bold;
    border: 0;
    outline: 0;
     background: transparent;
    border-bottom: 2px solid rgba(0, 41, 121, 1);
    margin-bottom: 20px;
    color: rgba(0, 41, 121, 1);
    text-align: center; */
  padding: 5px 10px;
  text-align: center;
  color: #1a1919 !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  background-color: #ffffff !important;
  border-radius: 6px !important;
  margin: auto;
  margin-bottom: 0px !important;
  width: 95%;
  border: none;
  outline: 0;
}

.foryou-wrapper .inner-div .takeagift input::placeholder {
  color: #1a1919 !important;
}

.takeagift img {
  width: 100%;
  display: none;
}
.foryou-wrapper .inner-div .takeagift {
  background-color: #88c4ec;
  border: none;
  padding: 18px;
  border-radius: 14px;
  max-width: 310px;
  margin: auto;
}
.foryou-wrapper .inner-div .takeagift p {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 12px;
}

.foryou-wrapper .inner-div .abc {
  /*35px*/
  /* position:absolute;
    top: 18%;
    padding-left: 50px;
    padding-right: 59px; */
  padding: 0 22px;
}
.giftcard-storelink {
  padding-top: 30px;
}
.inner-div .giftcard-storelink .store {
  flex-direction: column;
  justify-content: space-between;
}
.inner-div .giftcard-storelink .google-app {
  margin-right: 20px;
}
/* .inner-div .giftcard-storelink .store img
{
    transform: rotate(-17deg) translateX(0px);
    margin-left: 15px;
} */
.inner-div .giftcard-storelink .store img:last-child {
  /* margin-top: -25px; */
}
.footer {
  background: #18191a;
  /* height: 57px; */
}
.footer .copyright {
  display: flex;
  margin: auto;
}
.footer .footer-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer .footer-wrapper {
  padding: 15px 0;
  align-items: center;
}
.footer .copyright .copy {
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #dedede;
  font-size: 14px;
  line-height: 1.9;
  margin: 0;
}
.footer .copyright h6 {
  margin-bottom: 0;
  font-size: 18px;
  color: #ffffff;
}
.footer .copyright h6:last-child {
  margin-left: 20px;
}
.footer .top h6 {
  color: white;
}

.top-scroll,
.howItWorks {
  cursor: pointer;
}

.top-scroll {
  margin: 0;
  color: #dedede;
  font-weight: 250;
  font-size: 15px;
}

#CountNumber {
  background-color: white;
}

.loader-bottom {
  margin-top: -27px;
}

.form-control::-webkit-input-placeholder {
  font-weight: 600;
  color: #c8cacb;
}
.form-control::-moz-placeholder {
  font-weight: 600;
  color: #c8cacb;
}
.form-control:-ms-input-placeholder {
  font-weight: 600;
  color: #c8cacb;
}
.form-control:-moz-placeholder {
  font-weight: 600;
  color: #c8cacb;
}

/* for new page */
/* for new page */
/* for new page */
/* for new page */
/* for new page */
/* for new page */
/* for new page */
/* for new page */
/* for new page */
/* for new page */

html,
body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-smoothing: antialiased; */
}

/* .header{
    background-image: url(../images/Header_bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
} */

.header .header-nav ul li a {
  font-size: 14px;
  color: rgba(86, 86, 86, 1);
}
.header .header-nav ul li a span {
  color: #0090ff;
  font-weight: 500;
  text-decoration: underline;
  font-size: 14px;
}
.header .header-nav .collapse ul li a:hover {
  color: rgba(192, 32, 50, 1);
}
.gift-card {
  background: url(../images/email-bg.png);
  background-position: center;
  background-size: cover;
  min-height: 130vh;
  width: 100%;
  padding-bottom: 50px;
}
.gift-card .inner-gift-content {
  margin-top: 70px;
}
.gift-card .inner-gift-content p {
  font-size: 45px;
  font-weight: 500;
}
.gift-card .account-form {
  position: relative;
  max-width: 460px;
  margin: auto;
  margin-top: 20px;
  padding-top: 30px;
  background: rgba(255, 255, 255, 0.8) !important;
}
.gift-card .account-form h6 {
  font-size: 24px;
  font-weight: 500;
}
.account-form .form-group {
  margin-left: 30px;
}
.account-form .custom-control {
  margin-left: 30px;
}
.account-form .custom-control label {
  text-align-last: left;
  color: #818181;
  font-size: 14px;
}
.account-form .custom-control label::before {
  border-radius: 4px;
  background-color: white;
}
.account-form .custom-control label::after {
  border: 1px solid #c02032;
  border-radius: 4px;
  background-color: white;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url(../images/right.png);
  background-color: white;
}
.account-form .form-check {
  margin-left: 30px;
}
.account-form .form-group label {
  float: left;
  color: #95989b;
  font-size: 13px;
}
.account-form .form-group input {
  width: 95%;
}
.gift-card .acc-btn {
  margin-top: 34px;
}

.gift-card .acc-exist {
  margin-top: 22px;
  padding-bottom: 23px;
}
.gift-card .acc-exist p span {
  color: #0090ff;
  text-decoration: underline;
  font-size: 14px;
}
.gift-card .acc-exist p {
  color: #818181;
  font-size: 14px;
}
.footer {
  background: #18191a;

  /* height: 57px; */
}
.footer .copyright {
  display: flex;
  margin: auto;
}
.footer .footer-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}
.footer .copyright .copy {
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #dedede;
  font-size: 14px;
  line-height: 1.9;
}
.footer .copyright h6 {
  font-size: 18px;
  color: #ffffff;
}
.footer .copyright h6:last-child {
  margin-left: 20px;
}
.footer .top h6 {
  color: white;
}

.account-form form {
  padding: 20px;
}

.footer-bar {
  cursor: pointer;
}

.congrats {
  max-width: 100%;
  margin: auto;
  margin-top: 165px;
  height: 63vh;
  background: url(../images/email-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.congrats h3 {
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
}
.congrats p {
  font-size: 20px;
}
.congrats .congrats-btn {
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 29px;
  background: transparent;
  border: 1px solid #18191a;
  color: #18191a;
}

.congrats .congrats-btn a {
  color: #18191a;
}
.congrats .congrats-btn a:hover {
  color: white !important;
}
.congrats .congrats-btn:hover {
  background: #c02032;
}

.congrats-footer {
  margin-top: auto;
}
.whole-top-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.gift-page img {
  height: 65px;
}
.redeem-button {
  padding: 18px;
  text-transform: uppercase;
  background: #c02032;
  border: 1px solid #707070;
  border-radius: 6px;
  color: white;
}

.redeem-button:hover {
  background: #a71223;
  color: white;
}

.div-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.body-contents {
  height: 100vh;
  font-family: "Montserrat", sans-serif;
}

.body-contents .header {
  box-shadow: 0 0 8px #8d8d8d;
}

.body-contents .header .container .header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
}

.body-contents .header .container .header-inner .contact-div p {
  color: #565656;
  text-align: right;
}

.body-contents .header .container .header-inner .contact-div p a {
  color: #0091ff;
}

.body-contents .header .container .header-inner .contact-div p a:hover {
  text-decoration: none !important;
  color: #008bf5;
}

.body-contents .contents-main .container .error-div {
  padding: 70px 0 100px;
}

.body-contents .contents-main .container .error-div .error-div-inner {
  max-width: 1080px;
  text-align: center;
  background: url(../../assets/images/bakground.png) no-repeat center/cover;
  margin: auto;
  padding: 100px 235px;
}

.body-contents .contents-main .container .error-div .error-div-inner h1 {
  font-size: 229px;
  color: #0091ff;
  font-weight: 700;
}

.body-contents .contents-main .container .error-div .error-div-inner h2 {
  font-size: 35px;
  font-weight: 600;
}

.go-home__btn {
  background: #f1f9ff;
  padding: 13px 40px;
  border-radius: 50px;
  border: 1px solid #0091ff;
  margin-top: 10px;
  color: #0091ff;
}

.go-home__btn:hover {
  color: #f1f9ff;
  background: #0091ff;
}

.go-home__btn:focus {
  box-shadow: none;
}

.error-logo {
  max-height: 50px;
}

.gift-div .inner-div {
  position: unset !important;
  top: unset !important;
}
.gift-logo-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gift-logo {
  max-width: 220px;
  padding: 25px 20px;
}
.gift-logo-sm {
  max-width: 107px;
}

.giftRedeemCode input {
  padding: 5px 10px;
  text-align: center;
  color: #1a1919 !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  background-color: #ffffff !important;
  border-radius: 6px !important;
  margin: auto;
  margin-bottom: 0px !important;
}
.giftImgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.store-img {
  max-width: 170px;
  margin: 5px 12px;
}
.store-img img {
  background-color: white;
  border-radius: 9px;
}
.codeplaceholder::placeholder {
  color: #1a1919;
}
.giftImgDiv img {
  image-rendering: -webkit-optimize-contrast;
}
.gift-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  font-family: SF Pro Display;
}
.gift-grid-column {
}
.gift-grid-form-background {
  background-color: #f2f9fd;
}
.container-gift-grid {
  /* padding: 2vh 12vw; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-gap: 2vh;
  gap: 2vh;
  justify-content: flex-start;
  width: 450px;
  margin: 32px 120px 0px 0px;
}

.container-gift-grid-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin: 32px 0px 0px 100px;
}
.gift-text-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin-top: 32px;
  /* width: 30vw; */
  /* width: 86%; */
}
.gift-text-lg {
  color: #1a1919;
  font-family: SF Pro Display;
  font-size: 48px;
  font-style: normal;
  font-weight: 650;
  line-height: 54px; /* 100% */
}
.gift-text-sm {
  color: #1a1919;
  font-family: SF Pro Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.redeem-text-sm {
  color: #1a1919;
  font-family: SF Pro Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.gift-for-you img {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 60px;
  width: 100%;
}
.gift-subscription-header {
  color: #1a1919;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: auto;
  margin-left: auto;
}
.gift-subscription-text {
  color: #1a1919;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.gift-subscription {
  display: flex;
  padding: 12px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #f2f9fd;
  margin: auto;
}
.gift-form-steps {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1a1919;
  font-family: SF Pro Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.gift-form-step {
  display: flex;
  padding: 0px 0.5vw;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 10px;
  cursor: pointer;
  font-weight: 600;
}
.gift-form-step-number {
  border-radius: 28px;
  border: 2px solid #1a1919;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.gift-form-step-number-disabled {
  border-radius: 28px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: 2px solid #afafaf;
}
.gift-form-step-name {
}
.gift-form-step-line {
  width: 6.5vw;
  height: 1px;
  background: #afafaf;
}
.gift-form {
  display: flex;
  padding: 24px 36px 36px 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 36px;
  background: #88c4ec;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.gift-form-header {
  color: #fff;
  font-family: SF Pro Display;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-bottom: 12px;
}
.gift-form-inputs {
  display: flex;
  /* height: 84px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  align-self: stretch;
}
.gift-form-label {
  color: #fff;
  font-family: SF Pro Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.gift-form-inputs input {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  border: none;
  width: 100%;
  font-size: 16px;
}
.gift-form-inputs-stripe {
  padding: 12px 16px;
  border-radius: 12px;
  background: #fff;
  border: none;
  width: 100%;
}

.gift-form-inputs textarea {
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  border: none;
  font-size: 16px;
}
.gift-form-confirm {
  display: flex;
  padding: 20px 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 14px;
  border: 1px solid #707070;
  background: #034469;
}
.gift-form-confirm-text {
  color: #fff;
  font-family: SF Pro Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.gift-form-confirm-div {
  width: "100%";
  display: flex;
  justify-content: center;
}
.gift-form-step-disabled {
  color: #afafaf;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.gift-for-you {
  width: 100%;
}
.gift-for-you-step2 {
  width: 100%;
  height: fit-content;
}
.gift-text-note {
  color: #1a1919;
  font-family: SF Pro Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 20vw;
  overflow-wrap: break-word;
}
.gift-text-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 36px;
  width: 100%;
}
.gift-text-details span {
  color: #1a1919;
  font-family: SF Pro Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}
.gift-form-step2 {
  display: flex;
  width: 395px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.gift-form-subheader {
  color: #fff;
  text-align: center;
  font-family: SF Pro Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.gift-form-pricing {
  color: #fff;
  text-align: center;
  font-family: SF Pro Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.gift-form-pricing-box {
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1.5px solid #fff;
}
.gift-form-inputs-div {
  display: flex;
  gap: 16px;
  width: 100%;
}
.gift-form-promo-btn {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 12px;
  background: #034469;
  color: #fff;
  font-family: SF Pro Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100px;
  border: none;
}
.gift-form-promo-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100px;
}
.gift-form-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  margin-top: 4vh;
}
.gift-form-footer-text {
  color: #1a1919;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: SF Pro Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.gift-form-footer-text a {
  color: #88c4ec;
  leading-trim: both;
  text-edge: cap;
  font-family: SF Pro Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
}
.gift-form-help {
  color: #1a1919;
  font-family: SF Pro Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.gift-form-help-link {
  color: #1a1919;
  font-family: SF Pro Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}
.gift-content-container {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  align-items: center;
}

/* Confirmation */
.confirmation-box-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;
  align-self: stretch;
  border-radius: 16px;
  background: #f2f9fd;
  padding: 5vh 5svw;
  width: 50vw;
}
.confirmation-box-container h1 {
  color: #1a1919;
  text-align: center;
  font-family: SF Pro Display;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.confirmation-box-container h2 {
  color: #1a1919;
  text-align: center;
  font-family: SF Pro Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.gift-card-span {
  color: #1a1919;
  font-family: SF Pro Display;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.gift-relative-container {
  position: relative;
}
.gift-logo-div {
  width: 100%;
  display: flex;
  justify-content: start;
}
/* Confirmation */

input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../images/calendar-icon.svg") no-repeat;
  width: 20px;
  height: 20px;
}
/* placeholder text style */
input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field {
  color: #888;
  font-family: "sf pro display";
}

/* regular text style */
input[type="date"].date-input--has-value::-webkit-datetime-edit-text,
input[type="date"].date-input--has-value::-webkit-datetime-edit-month-field,
input[type="date"].date-input--has-value::-webkit-datetime-edit-day-field,
input[type="date"].date-input--has-value::-webkit-datetime-edit-year-field {
  color: #000000;
  font-family: "sf pro display";
}
input:focus {
  background-color: #e8fefa;
  outline: none; /* Remove the default focus outline (some browsers add a blue outline by default) */
}
textarea:focus {
  background-color: #e8fefa;
  outline: none; /* Remove the default focus outline (some browsers add a blue outline by default) */
}
.StripeElement--focus {
  background-color: #e8fefa;
  outline: none; /* Remove the default focus outline (some browsers add a blue outline by default) */
}
/* kickoff */
.left-logo-div {
  position: absolute;
  top: 5vh;
  left: 3vw;
}
.left-logo-div > img {
  width: 8vw;
}
.left-banner-img > img {
  height: 100vh;
  object-fit: cover;
  /* margin-top: -130px; */
}
.right-div {
  width: 100%;
  background: white;
  text-align: center;
}
.right-div-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 30vw;
  margin-left: auto;
  margin-right: auto;
}
.right-text-div h2 {
  color: #1a1919;
  text-align: center;
  font-family: SF Pro Display;
  font-size: 72px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px; /* 100% */
}
.yoga-image {
  width: 50vw;
  height: 100vh;
  overflow: hidden;
  object-fit: cover;
}
.right-confirmation-redeem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  font-family: SF Pro Display;
}
.right-confimation-redeem-container {
  display: flex;
  width: 610px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 54px;
}
.confirmation-unplug-detils {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
  align-items: center;
}
.confirmation-unplug-detils > h2 {
  color: #1a1919;
  text-align: center;
  font-family: SF Pro Display;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px; /* 100% */
}
.confirmation-unplug-detils > span {
  color: #1a1919;
  text-align: center;
  font-family: SF Pro Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 30vw;
}
.right-bubble {
  position: relative;
  margin: 40px 20px;
  background-color: #80c5ee;
  padding: 16px;
  border-radius: 26px;
}
.right-bubble span {
  font-size: 18px;
  color: #ffff;
}
.exclamation-icon {
  position: absolute;
  height: 30px;
  width: 30px;
  left: -10px;
  top: -10px;
  background-color: black;
  color: #fff;
  z-index: 10;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-confirmation-app-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.right-confirmation-app-details > span {
  color: #1a1919;
  font-family: SF Pro Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.right-confirmation-app-details > span > a {
  color: #88c4ec;
  font-family: SF Pro Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.app-images {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.app-images > a {
  width: 215.056px;
  height: 64px;
}
.unplug-logo-redeem {
  width: 10vw;
  object-fit: contain;
  position: absolute;
  top: 10vh;
  left: 5vw;
}
.gift-grid-column-left {
  position: relative;
  display: flex;
  justify-content: end;
}
.gift-grid-column-right {
  position: relative;
  display: flex;
  justify-content: start;
}
/* kickoff */

button:disabled {
  background-color: #dddddd; /* Light gray background */
  color: #aaaaaa; /* Light gray text color */
  cursor: not-allowed; /* Disabled cursor style */
}

/* hiding the badge */
.grecaptcha-badge {
  visibility: hidden !important;
}
/* hiding the badge */

/* input[type="date"],
input[type="time"] {
  -webkit-appearance: none;
} */
.confirmation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 54px;
}
